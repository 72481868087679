




















































































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  createPostController = createPostController

  coverAvatar(postStore: PostStore) {
    if (postStore && postStore?.medias[0] && postStore?.medias[0].url) return postStore?.medias[0].url
    else return ''
  }

  redirectToPostDetail() {
    this.$router.push(`/community/post/${this.postStore.post.id}`)
  }
  get content() {
    return this.postStore.contentBlocks[0]?.rawContent
  }
}
